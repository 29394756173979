export default {
  "bu-default": () => import("/vercel/path0/layouts/bu/default.vue").then(m => m.default || m),
  "bu-simple": () => import("/vercel/path0/layouts/bu/simple.vue").then(m => m.default || m),
  default: () => import("/vercel/path0/layouts/default.vue").then(m => m.default || m),
  "te-side-menu": () => import("/vercel/path0/layouts/te/side-menu.vue").then(m => m.default || m),
  "te-simple-counseling": () => import("/vercel/path0/layouts/te/simple/counseling.vue").then(m => m.default || m),
  "te-simple-engineer": () => import("/vercel/path0/layouts/te/simple/engineer.vue").then(m => m.default || m),
  "te-simple-lp": () => import("/vercel/path0/layouts/te/simple/lp.vue").then(m => m.default || m),
  "te-simple-marketer": () => import("/vercel/path0/layouts/te/simple/marketer.vue").then(m => m.default || m),
  "te-simple-sidejob": () => import("/vercel/path0/layouts/te/simple/sidejob.vue").then(m => m.default || m),
  "te-simple": () => import("/vercel/path0/layouts/te/simple/simple.vue").then(m => m.default || m),
  "te-simple-webdesigner": () => import("/vercel/path0/layouts/te/simple/webdesigner.vue").then(m => m.default || m),
  "yate-side-menu": () => import("/vercel/path0/layouts/yate/side-menu.vue").then(m => m.default || m),
  "yate-simple-nolink": () => import("/vercel/path0/layouts/yate/simple-nolink.vue").then(m => m.default || m),
  "yate-simple": () => import("/vercel/path0/layouts/yate/simple.vue").then(m => m.default || m)
}