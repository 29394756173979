import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/vercel/path0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/vercel/path0/plugins/Vue3Lottie.client.ts";
import addComma_zsZdReCwur from "/vercel/path0/plugins/addComma.ts";
import api_GFfDXud5Cr from "/vercel/path0/plugins/api.ts";
import assetsUrl_wt8xlYPD9d from "/vercel/path0/plugins/assetsUrl.ts";
import criteoEvent_client_Ub7HXyH0Bh from "/vercel/path0/plugins/criteoEvent.client.ts";
import deviceSize_client_heep7EvA0x from "/vercel/path0/plugins/deviceSize.client.ts";
import errorHandler_client_HLECVax37c from "/vercel/path0/plugins/errorHandler.client.ts";
import google_recaptcha_client_VwhKLB8VKY from "/vercel/path0/plugins/google-recaptcha.client.ts";
import head_xR53WE0jXG from "/vercel/path0/plugins/head.ts";
import headScript_client_wUJ9hBPR21 from "/vercel/path0/plugins/headScript.client.ts";
import host_sIdR4Utqpj from "/vercel/path0/plugins/host.ts";
import karte_client_BDXfqDefGq from "/vercel/path0/plugins/karte.client.ts";
import marketingParams_client_PjHyLZp2Bq from "/vercel/path0/plugins/marketingParams.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import vue_inview_5ki6eaYymI from "/vercel/path0/plugins/vue-inview.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  Vue3Lottie_client_bMZiOS6AB0,
  addComma_zsZdReCwur,
  api_GFfDXud5Cr,
  assetsUrl_wt8xlYPD9d,
  criteoEvent_client_Ub7HXyH0Bh,
  deviceSize_client_heep7EvA0x,
  errorHandler_client_HLECVax37c,
  google_recaptcha_client_VwhKLB8VKY,
  head_xR53WE0jXG,
  headScript_client_wUJ9hBPR21,
  host_sIdR4Utqpj,
  karte_client_BDXfqDefGq,
  marketingParams_client_PjHyLZp2Bq,
  sentry_client_shVUlIjFLk,
  vue_inview_5ki6eaYymI
]